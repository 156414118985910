import 'core-js';
import "regenerator-runtime/runtime";
import rwxPreload from 'roseworx/js/modules/rwxPreload';
import rwxSkrollX from 'roseworx/js/modules/rwxSkrollX';
import rwxForms from 'roseworx/js/core/rwxForms';

window.addEventListener('load', ()=>{
  let d = document.getElementById('current-date');
  if(d) {
    let da = new Date();
    d.innerHTML = da.getFullYear();
  }

  const nav = document.getElementById('navigation');
  const openNav = document.getElementById('navigation-open');
  const closeNav = document.getElementById('navigation-close');

  openNav && openNav.addEventListener('click', ()=>{nav.classList.add('open')});
  closeNav && closeNav.addEventListener('click', ()=>{nav.classList.remove('open')});

});